<template>
  <main class="step-page5">
    <Heading :title="'Dla optymalnego wsparcia'" />
    <section class="step5 step step--fifth">
      <div class="step5__inner">
        <div class="step5__info" data-aos="fade-in">
          <p>
            ...nasi doświadczeni Partnerzy z Twojej okolicy skontaktują się z
            Tobą po otrzymaniu tego formularza.
          </p>
          <p>
            <strong
              >Potrzebujemy Twoich danych kontaktowych do indywidualnej
              konsultacji i oferty</strong
            >
          </p>
        </div>
        <!-- / step5__info -->

        <div class="step5__form" data-aos="fade-in" data-aos-delay="250">
          <input
            class="step5__input"
            type="text"
            placeholder="Imię"
            :value="personalData.name"
            :maxlength="valuesLength.name"
            @input="updatePersonalData('name', $event)"
            :class="{ error: errors.includes('name') }"
            @focus="clearError('name')"
          />
          <input
            class="step5__input"
            type="text"
            placeholder="Nazwisko"
            :value="personalData.surname"
            :maxlength="valuesLength.surname"
            @input="updatePersonalData('surname', $event)"
            :class="{ error: errors.includes('surname') }"
            @focus="clearError('surname')"
          />
          <input
            class="step5__input"
            type="text"
            placeholder="Firma"
            :value="personalData.company"
            :maxlength="valuesLength.company"
            @input="updatePersonalData('company', $event)"
            :class="{ error: errors.includes('company') }"
            @focus="clearError('company')"
          />
          <input
            class="step5__input"
            type="text"
            placeholder="Telefon (w przypadku pytań)"
            :value="personalData.phone"
            :maxlength="valuesLength.phone"
            @input="updatePersonalData('phone', $event)"
            :class="{ error: errors.includes('phone') }"
            @focus="clearError('phone')"
          />
          <input
            class="step5__input"
            type="email"
            placeholder="E-mail"
            :value="personalData.email"
            @input="updatePersonalData('email', $event)"
            :maxlength="valuesLength.email"
            :class="{ error: errors.includes('email') }"
            @focus="clearError('email')"
          />
          <div class="step5__input-container">
            <input
              class="step5__input"
              type="text"
              placeholder="Miasto"
              :value="personalData.city"
              :maxlength="valuesLength.city"
              @input="updatePersonalData('city', $event)"
              :class="{ error: errors.includes('city') }"
              @focus="clearError('city')"
            />
            <input
              class="step5__input"
              type="text"
              placeholder="Kod pocztowy"
              :value="personalData.postcode"
              :maxlength="valuesLength.postcode"
              @input="updatePersonalData('postcode', $event)"
              :class="{ error: errors.includes('postcode') }"
              @focus="clearError('postcode')"
            />
          </div>
          <div class="step5__input-container">
            <input
              class="step5__input"
              type="text"
              placeholder="Ulica"
              :value="personalData.street"
              :maxlength="valuesLength.street"
              @input="updatePersonalData('street', $event)"
              :class="{ error: errors.includes('street') }"
              @focus="clearError('street')"
            />
            <input
              class="step5__input"
              type="text"
              placeholder="Numer"
              :value="personalData.number"
              :maxlength="valuesLength.number"
              @input="updatePersonalData('number', $event)"
              :class="{ error: errors.includes('number') }"
              @focus="clearError('number')"
            />
          </div>
        </div>
        <!-- / step5__form -->

        <div class="step5__privacy" data-aos="fade-in" data-aos-delay="500">
          <textarea
            class="step5__input textarea"
            placeholder="Inne uwagi (opcjonalnie)"
            :value="personalData.info"
            :maxlength="valuesLength.info"
            @input="updatePersonalData('info', $event)"
          ></textarea>
          <div class="e-checkbox" :class="{ error: errors.includes('agree') }">
            <input
              class="e-checkbox__checkbox"
              type="checkbox"
              :checked="personalData.agree"
              @change="
                updatePersonalData('agree', $event, true);
                clearError('agree');
              "
            />
            <div class="e-checkbox__checked"></div>
            <p class="e-checkbox__name">
              Akceptuję
              <a
                href="https://dobroplast.pl/wp-content/uploads/2021/10/Polityka_prywatnos%CC%81ci_2021.pdf"
                target="_blank"
                >politykę prywatności</a
              >.
            </p>
          </div>
          <!-- <p class="step5__apology">
            W czasie wakacji mogą wystąpić opóźnienia w skontaktowaniu się z Tobą. Dziękujemy za wyrozumiałość.
          </p> -->
        </div>
        <!-- / step5__privacy -->
      </div>
      <!-- / step5__inner -->

      <Bottom>
        <Button
          :path="'step4'"
          :title="'Wróć'"
          :classes="'e-button--secondary'"
        />
        <Button
          :title="'Dalej'"
          :classes="'e-button--primary'"
          @clicked="redirectToNextStep()"
        />
      </Bottom>
    </section>
    <!-- / step5  -->
  </main>
</template>

<script>
import Date from "@/components/Date.vue";
import Bottom from "@/components/Bottom.vue";
import Button from "@/components/Button.vue";
import Heading from "@/components/Heading.vue";

export default {
  name: "Step5",
  components: {
    Date,
    Bottom,
    Button,
    Heading
  },
  data() {
    return {
      errors: [],
      valuesLength: {
        name: 32,
        surname: 64,
        company: 64,
        phone: 16,
        email: 64,
        city: 64,
        postcode: 6,
        street: 64,
        number: 16,
        info: 512
      }
    };
  },
  methods: {
    updatePersonalData(handle, event, checked = false) {
      let value = !checked ? event.target.value : event.target.checked;
      if (typeof value == "string") {
        value = this.validateValuesLength(handle, value);
      }
      this.$store.commit("updatePersonalData", { handle, value });
    },
    validateValuesLength(handle, value) {
      return value.substring(0, this.valuesLength[handle]);
    },
    validateForm() {
      let validatedFields = [
        {
          field: "name",
          required: true
        },
        {
          field: "surname",
          required: true
        },
        {
          field: "company",
          required: true
        },
        {
          field: "phone",
          required: true
        },
        {
          field: "email",
          regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          required: true
        },
        {
          field: "city",
          required: true
        },
        {
          field: "postcode",
          regex: /^([0-9]{2})(-[0-9]{3})?$/i,
          required: true
        },
        {
          field: "street",
          required: true
        },
        {
          field: "number",
          required: true
        },
        {
          field: "agree",
          required: true
        }
      ];

      validatedFields.forEach(item => {
        this.validateSingleField(item);
      });

      return !this.errors.length;
    },
    validateSingleField(item) {
      if (item.required && !this.personalData[item.field]) {
        if (!this.errors.includes(item.field)) {
          this.errors.push(item.field);
        }

        return;
      }

      if ("regex" in item) {
        if (!item.regex.test(this.personalData[item.field])) {
          if (!this.errors.includes(item.field)) {
            this.errors.push(item.field);
          }
        }

        return;
      }

      this.clearError(item.field);
    },
    clearError(field) {
      let index = this.errors.indexOf(field);
      if (index > -1) {
        this.errors.splice(index, 1);
      }
    },
    redirectToNextStep() {
      if (this.validateForm()) {
        this.$router.push({ path: "step6" }).catch(() => {});
      }
    }
  },
  computed: {
    personalData() {
      return this.$store.state.personalData;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.checkRoute(to, from)) {
      next();
    }

    if (!this.validateForm()) {
      return;
    }

    next();
  }
};
</script>

<style scoped lang="scss">
.step5 {
  background-color: $color-bg-alt;

  &__inner {
    margin-bottom: 30px;

    @media #{$lg} {
      @include flex(flex-start, flex-start, wrap);
      margin-bottom: 40px;
    }
    @media #{$xl} {
      flex-wrap: nowrap;
    }
  }

  &__info {
    @extend %product-name;
    color: $color-text;
    margin-bottom: 30px;

    @media #{$lg} {
      flex: 0 0 100%;
    }
    @media #{$xl} {
      flex: 0 0 20%;
      margin-bottom: 0;
    }

    p:not(:last-of-type) {
      margin-bottom: 30px;
    }
    strong {
      font-weight: 700;
    }
  }

  &__form {
    margin-bottom: 20px;

    @media #{$lg} {
      padding: 0 10px 0 0;
      flex: 0 0 55%;
      margin-bottom: 0;
    }
    @media #{$xl} {
      padding: 0 35px 0 50px;
      flex: 0 0 50%;
    }
  }

  &__input {
    @include transition();
    @extend %product-name;
    color: $color-text;
    border: 1px solid $color-white;
    background-color: $color-white;
    width: 100%;
    height: 50px;
    margin-bottom: 19px;
    padding: 0 20px;
    resize: none;

    &::placeholder {
      color: $color-text;
    }

    &:focus {
      border: 1px solid $color-primary;
    }

    &.textarea {
      padding: 16px 20px;
      min-height: 200px;

      @media #{$lg} {
        min-height: 326px;
      }
    }

    &.error {
      border: 1px solid $color-secondary;
    }
  }

  &__privacy {
    @media #{$lg} {
      flex: 0 0 45%;
      padding-left: 10px;
    }
    @media #{$xl} {
      flex: 0 0 30%;
      padding-left: 0;
    }
  }

  &__input-container {
    @include flex(flex-start, center, nowrap);

    &:last-of-type {
      .step5__input {
        margin-bottom: 0;
      }
    }

    .step5__input {
      &:last-of-type {
        margin-left: 6px;
        flex: 0 0 50%;
      }
    }
  }

  &__apology {
    @extend %info;
    font-weight: 700;
    color: $color-primary;
    margin-top: 30px;

    @media #{$md} {
      margin-top: 40px;
    }
  }
}

.e-checkbox {
  @include flex(flex-start, flex-start, nowrap);
  position: relative;

  &__name {
    @extend %info;
    color: $color-text;

    a {
      color: $color-primary;
      text-decoration: underline;
      position: relative;
      z-index: 4;
      display: inline-block;
    }
  }

  &__checkbox {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 2;

    &:checked ~ .e-checkbox__checked {
      background: url("~@/assets/images/confirm.svg");
      background-size: 12px auto;
      background-position: center;
      background-repeat: no-repeat;
      background-color: $color-primary;
    }
  }

  &__checked {
    @include transition($property: background-color);
    width: 17px;
    min-width: 17px;
    flex: 17px 0 0;
    height: 17px;
    border: 2px solid $color-primary;
    margin-right: 9px;
  }

  &.error {
    .e-checkbox__checked {
      border: 2px solid $color-secondary;
    }
  }
}
</style>
